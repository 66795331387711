<template>
  <div class="projectInfo">
    <div class="projectInfoBox common-main">
      <div class="headTitle">
        <h2 class="title_zh">
          {{data.title}}
          
        </h2>
        <h2 class="title_en">
          {{data.sub_title}}
        </h2>
        <div class="fenxBox">
            <img class="fenx" src="../assets/images/icon_fenx.png" alt />
            <p>分享</p>
            <share/>
          </div>
      </div>
      
      <div class="projectInfoContent" v-html="data.content"></div>
    </div>
    <Relate :proId="$route.params.id"></Relate>
  </div>
</template>

<script>
import Relate from "@/components/relate.vue";
import share from "@/components/share.vue";
export default {
  data() {
    return {
      data: "",
    };
  },
  computed: {},
  components: { Relate,share },
  created() {
    this.getData();
  },
  updated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  watch: {
    $route(to, from) {
      if (this.$route.params.id) {
        this.getData();
      }
    },
  },
  methods: {
    getData() {
      this.axios
        .get(
          this.GLOBAL.serverSrc + "/wc/proDetail?id=" + this.$route.params.id
        ) // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            this.data = res.data.data;
          }
        });
    },
  },
  mounted() {
    // this.getData();
  },
};
</script>

<style lang="scss" scoped>
.projectInfo {
  width: 100%;
  height: 100%;
}
.projectInfoBox {
  padding: 110px 120px 0 120px;
  width: 100%;
  .headTitle {
    border-bottom: 1px solid #ccc;
    .title_zh {
      color: #000;
      font-size: 34px;
      cursor: pointer;
      font-weight: 800;
      
    }
  }
  .fenxBox {
        float: right;
        cursor: pointer;
        margin-top:15px;
        .fenx {
          width: 20px;
          height: 20px;
          float: right;
        }
        p {
          font-size: 12px;
          color: #808080;
        }
      }
  .title_en {
    cursor: pointer;
    font-weight: 600;
    margin-bottom: 60px;
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .fenxBox {float: right; margin-top:10px;}
  .projectInfoBox .headTitle .title_zh {
    position: relative;
    padding-right: 40px;
  }
  .projectInfoBox .headTitle .title_zh .fenxBox {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>