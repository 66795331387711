<template>
  <div class="relate">
    <div class="imgList clearfloat">
      <h4>相关项目</h4>
      <router-link
        v-for="(item,index) in data"
        :key="index"
        :to="{ name: 'projectInfo', params: { id: item.id }}"
      >
        <p class="projectTit">{{item.title}}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.onHeaddata"),
      data: {
        id: "",
        title: "",
      },
    };
  },
  props: ["proId"],
  components: {
    Head,
  },
  created() {},
  methods: {
    getData() {
      this.axios
        .get(this.GLOBAL.serverSrc + "/wc/proRelate?id=" + this.proId)
        .then((res) => {
          console.log(res);
          this.data = res.data.data;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.relate {
  height: 100%;
  width: 100%;
  margin-left: 150px;
  padding-bottom: 50px;
  .imgList .projectTit:hover {
    position: relative;
  }
  .imgList .projectTit:hover::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12px;
    background: #0066b2;
    opacity: 0.5;
  }
  div.imgList {
    > h4 {
      color: #000;
      line-height: 26px;
      font-weight: 600;
      font-size: 14px;
      display: inline-block;
      width: 90px;
    }
    > a {
      width: calc(100% - 90px);
      float: right;
    }
    .projectTit {
      color: #000;
      font-size: 14px;
      line-height: 26px;
      font-weight: 400;
      display: inline-block;
      padding: 0 1px;
    }
  }
}
@media (max-width: 768px) {
  .relate {
    margin-left: 30px;
    padding-bottom: 0.1rem;
    div.imgList {
      > h4 {
        width: 100%;
      }
      > a {
        width: 100%;
        display: block;
      }
    }
  }
}
</style>