<template>
  <div class="header">
    <img class="head_img" :src="headdata.imgUrl" alt />
    <div class="headText view justify-center p-l-20-p">
      <p class="tlt_zh m-b-13">{{headdata.headTitZh}}</p>
      <p class="tlt_en" v-html="headdata.headTitEn"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["headdata"],
};
</script>

<style lang="scss" scoped>
.header {
  height: auto;
  width: 100%;
  position: relative;
  .head_img {
    width: 100%;
    height: 100%;
  }
   .p-l-20-p {padding-left: 200px;}
  .headText {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
   
    p {
      text-align: left;
      color: #fff;
      font-size: 26px;
      font-family: "Source Han Sans CN";
      font-weight: 600;
      max-width: 990px;
      line-height: 1.1;
    }
    .tlt_en {
      font-size: 23px;
      font-family: "Helvetica";
      max-width: 80%;
      line-height: 1.5;
    }
  }
}
@media (max-width: 768px) {
  .header .headText {
    padding-left: 0.5rem;
  }
  .p-l-20-p {padding-left: 0.5rem;}
  .header .headText p {
    font-size: 0.24rem;
    line-height: inherit;
  }
  .header .headText .tlt_en {
    font-size: 0.22rem;
    line-height: inherit;
  }
}
</style>